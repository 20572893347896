import { ethers } from "ethers";
const lendContractAddress = gon.lendContractAddress;
const defautGas = 616883;
let signer;

window.coinToUsd = function coinToUsd(price, symbol = null) {
  var id = "basecoin_live_price";
  return " ($" + parseFloat(price) * $("#" + id).val() + ")";
};

function showMagnificPopup(id) {
  $.magnificPopup.close();
  $.magnificPopup.open({
    closeOnBgClick: false,
    enableEscapeKey: false,
    items: {
      src: id,
    },
    type: "inline",
  });
}

function convertInterestRate(interestRate) {
  return parseFloat(interestRate).toFixed(1) * 10;
}

//-------------------------- Put on lend

function savePutOnLend() {
  var $form = $("#new_lend");

  var request = $.ajax({
    url: $form.attr("action"),
    type: "POST",
    async: false,
    data: $form.serializeArray(),
    dataType: "script",
  });
  request.done(function (msg) {
    console.log("Put on Lend success.");
  });
  request.fail(function (jqXHR, textStatus) {
    show_modal("#lendModal");
    console.log("Put onLend failed. Please contact support");
  });
}

async function putOnLend() {
  $.magnificPopup.close();
  $.magnificPopup.open({
    closeOnBgClick: false,
    enableEscapeKey: false,
    items: {
      src: "#priceChange",
    },
    type: "inline",
  });

  approveLendNFT("putonLend"); //TODO: retry not available
}

function putOnLendValidation() {
  if (!validFloat($("#lend_min_amount").val())) {
    toastr.error("Please enter valid prices");
    return false;
  } else if ($("#lend_erc20_token_id").val() == "") {
    toastr.error("Please select crypto");
    return false;
  }
  // else if ($('#lend_min_duration').val() < 1) {
  //   toastr.error('Lend duration should be atleast 1 day');
  //   return false;
  // }
  else if ($("#lend_interest_rate").val() <= 0) {
    toastr.error("Interest rate should be greater than 0%");
    return false;
  } else if (
    $("#lend_quantity").length == 1 &&
    isNaN(parseInt($("#lend_quantity").val()))
  ) {
    toastr.error("Please enter quantity");
    return false;
  } else {
    putOnLend();
    return false;
  }
}

//-------------------------- Lend bidding

window.lendBidPopupCalculation = async function lendBidPopupCalculation(
  feePercentage
) {
  var sym = $("#lend_bid_currency :selected").text();
  var contractAddress = $("#lend_bid_currency :selected").attr("address");
  var decimals = $("#lend_bid_currency :selected").attr("decimals");
  if ($("#lend_bid_quantity").val()) {
    var qty = $("#lend_bid_quantity").val() || 0;
  } else {
    var qty = 1;
  }
  var price = $("#lend_bid_amt").val() || 0;
  var payAmt = multipliedBy(price, qty);
  var serviceFee = percentageOf(feePercentage, payAmt);
  var totalAmt = plusNum(payAmt, serviceFee);
  $("#lend-bid-amt-dp").html(mergeAmountSymbol(serviceFee, sym));
  var biddingAssetBalance =
    (await tokenBalance(contractAddress, decimals)) || 0;
  $("#erc20_balance").text(biddingAssetBalance);
  $("#lend-biding-asset-balance").text(
    mergeAmountSymbol(biddingAssetBalance, sym)
  );
  $("#lend-bid-total-amt-dp").html(mergeAmountSymbol(totalAmt, sym));
  $("#lend-bid-total-amt-dp").attr("bidAmt", totalAmt);
  $("#lend-bid-total-amt-dp").attr("bidPayAmt", payAmt);
  $("#lend-bid-total-usd").html(coinToUsd(totalAmt, sym));
};

window.erc20TokenLendBidApproveFailed = function erc20TokenLendBidApproveFailed(
  errorMsg
) {
  toastr.error(errorMsg);
  hideAll();
  $(".convertDone").removeClass("hide");
  $(".approveLendBidRetry").removeClass("hide");
  $(".signLendBidStart").removeClass("hide");
};

function saveLendBid(collectionId, sign, quantity, bidDetails) {
  var request = $.ajax({
    url: `/collections/${collectionId}/lend_bids`,
    type: "POST",
    async: false,
    data: { sign: sign, quantity: quantity, details: bidDetails },
    dataType: "script",
  });
  request.done(function (msg) {
    console.log("Lend Bidding success.");
  });
  request.fail(function (jqXHR, textStatus) {
    console.log("Lend Bidding failed. Please contact support");
  });
}

window.lendBidSignSuccess = function lendBidSignSuccess(collectionId) {
  toastr.success("Lend Bidding succces.");
  window.location.href = "/collections/" + collectionId;
};

window.lendBidSignFailed = function lendBidSignFailed(errorMsg) {
  toastr.error(errorMsg);
  hideAll();
  $(".convertDone").removeClass("hide");
  $(".approveLendBidDone").removeClass("hide");
  $(".signLendBidRetry").removeClass("hide");
};

window.lendBidSignAsset = async function lendBidSignAsset(
  contractAddress,
  lender,
  owner_address,
  token_id,
  asset_address,
  bidPayAmt,
  payingTokenAddress,
  collectionId,
  decimals = 18
) {
  try {
    var qty = $("#lend_bid_quantity").val();
    var intrest_rate = $("#lend_interest_rate").val();
    var duration = $("#lend_duration").val();
    var currency = $("#lend_bid_currency option:selected").val();
    decimals = gon.owntokenSymbol.includes(
      $("#lend_bid_currency option:selected").text()
    )
      ? 6
      : 18;
    var amount = toNum($("#lend-bid-total-amt-dp").attr("bidAmt"));
    var bidPayAmt = toNum($("#lend-bid-total-amt-dp").attr("bidpayamt"));
    var amountInDec = roundNumber(mulBy(amount, 10 ** decimals), 0);
    var nonce_value = await getNonceValue(collectionId);
    //var messageHash = window.web3.utils.soliditySha3(contractAddress, lender, owner_address, token_id, asset_address, amountInDec, qty, nonce_value);
    var messageHash = ethers.utils.solidityKeccak256(
      [
        "address",
        "address",
        "address",
        "uint256",
        "address",
        "uint256",
        "uint256",
        "uint256",
      ],
      [
        contractAddress,
        lender,
        owner_address,
        token_id,
        asset_address,
        amountInDec,
        qty,
        nonce_value,
      ]
    );

    var account = await getCurrentAccount();
    messageHash = ethers.utils.arrayify(messageHash);
    const signer = gon.provider.getSigner();
    var signature = await signer.signMessage(messageHash);

    await saveLendBid(collectionId, signature, qty, {
      asset_address: asset_address,
      token_id: token_id,
      quantity: qty,
      amount: bidPayAmt,
      amount_with_fee: amount,
      payment_token_address: payingTokenAddress,
      payment_token_decimals: decimals,
      interest_rate: intrest_rate,
      duration: duration,
    });

    await saveNonceValue(collectionId, signature, nonce_value);
    return window.lendBidSignSuccess(collectionId);
  } catch (err) {
    console.error(err);
    return window.lendBidSignFailed(err["message"]);
  }
};

window.initLendBidSignProcess = async function initLendBidSignProcess(
  contractAddress
) {
  hideAll();
  $(".convertDone").removeClass("hide");
  $(".approveLendBidDone").removeClass("hide");
  $(".signLendBidProgress").removeClass("hide");
  var details = fetchCollectionDetails(null, contractAddress, "LendBid");
  var lender = await getCurrentAccount();
  if (details) {
    lendBidSignAsset(
      lendContractAddress,
      lender,
      details["owner_address"],
      details["token_id"],
      details["asset_address"],
      toNum($("#lend-bid-total-amt-dp").attr("bidPayAmt")),
      details["pay_token_address"],
      details["collection_id"]
    );
  } else {
    lendBidSignFailed("Unable to fetch tokan details. Please try again later");
  }
};

window.erc20TokenLendBidApproveSuccess =
  function erc20TokenLendBidApproveSuccess(transactionHash, contractAddress) {
    $(".approveLendBidProgress").addClass("hide");
    $(".approveLendBidDone").removeClass("hide");
    var contractAddress = $("#lend_bid_currency option:selected, this").attr(
      "address"
    );
    initLendBidSignProcess(contractAddress);
  };

window.erc20TokenLendContractApprove =
  async function erc20TokenLendContractApprove(
    contractAddress,
    contractType,
    amount,
    decimals = 18,
    sendBackTo = "Lend"
  ) {
    try {
      console.log(
        contractAddress,
        contractType,
        gon.collection_data["contract_shared"]
      );
      amount = roundNumber(mulBy(amount, 10 ** decimals), 0);
      const approveERC = await fetchContract(
        contractAddress,
        contractType,
        gon.collection_data["contract_shared"]
      );
      var account = await getCurrentAccount();
      var gasPrices = await gasPrice();
      var balance = await approveERC.allowance(account, lendContractAddress);
      amount = BigInt(parseInt(balance) + parseInt(amount)).toString();

      var receipt = await approveERC.approve(lendContractAddress, amount, {
        from: account,
        gasLimit: defautGas,
        gasPrice: String(gasPrices),
      });

      if (sendBackTo == "Lend") {
        return window.erc20TokenLendBidApproveSuccess(
          receipt.transactionHash,
          contractAddress
        );
      } else if (sendBackTo == "LendPay") {
        return window.approveERC20TokenLendPaySuccess(
          receipt.transactionHash,
          contractAddress
        );
      }
    } catch (err) {
      console.error(err);
      if (sendBackTo == "Lend") {
        return window.erc20TokenLendBidApproveFailed(err["message"]);
      } else if (sendBackTo == "LendPay") {
        return window.erc20TokenLendPayApproveFailed(err["message"]);
      }
    }
  };

window.lendBidApproveProcess = function lendBidApproveProcess(
  contractAddress,
  decimals = 18
) {
  hideAll();
  $(".convertDone").removeClass("hide");
  $(".approveLendBidProgress").removeClass("hide");
  $(".signLendBidStart").removeClass("hide");
  showMagnificPopup("#placeLend");

  erc20TokenLendContractApprove(
    contractAddress,
    "erc20",
    $("#lend-bid-total-amt-dp").attr("bidAmt"),
    decimals
  );
};

window.lendBidConvertSuccess = function lendBidConvertSuccess(transactionHash) {
  var contractAddress = $("#lend_bid_currency :selected").attr("address");
  lendBidApproveProcess(contractAddress);
};

// Cloned from initBidProcess
window.initLendBidProcess = async function initLendBidProcess(
  contractAddress,
  contractDecimal
) {
  var curErc20Balance = $("#erc20_balance").text();
  //var ethBalance = $('#eth_balance').text()
  var ethBalance = await window.ethBalance(await getCurrentAccount());
  var totalAmt = $("#lend-bid-total-amt-dp").attr("bidAmt");
  var symbol = $("#lend_bid_currency :selected").text();
  if (isGreaterThanOrEqualTo(curErc20Balance, totalAmt)) {
    console.log("ONe");
    $(".convertEth").addClass("hide");
    lendBidApproveProcess(contractAddress);
  } else if (
    symbol === gon.tokenSymbol &&
    isGreaterThanOrEqualTo(ethBalance, totalAmt)
  ) {
    console.log("Two");
    convertMaticToWmatic(totalAmt - curErc20Balance, "Lend");
  } else {
    console.log("THree");
    $("#biddingForm :input").prop("disabled", false);
    $.magnificPopup.close();

    return toastr.error("Not enough balance");
  }
};

function lendBidValidation() {
  if (!validFloat($("#lend_bid_amt").val())) {
    toastr.error("Please enter valid price");
    return false;
  } else if (
    $("#lend_bid_amt").val() < parseFloat($("#lend_bid_amt").data("min-val"))
  ) {
    toastr.error(
      "Bid value should be atleat " +
        $("#lend_bid_amt").data("min-val") +
        " " +
        $("#lend_bid_amt").data("currency")
    );
    return false;
  } else if ($("#lend_bid_currency").val() == "") {
    toastr.error("Please select crypto");
    return false;
  }
  // else if ($('#lend_duration').val() < 1) {
  //   toastr.error('Lend duration should be atleast 1 day');
  //   return false;
  // }
  else if ($("#lend_interest_rate").val() <= 0) {
    toastr.error("Interest rate should be greater than 0%");
    return false;
  } else if (
    $("#lend_quantity").length == 1 &&
    isNaN(parseInt($("#lend_quantity").val()))
  ) {
    toastr.error("Please enter quantity");
    return false;
  } else {
    var contractAddress = $("#lend_bid_currency :selected").attr("address");
    var decimals = $("#lend_bid_currency :selected").attr("decimals");

    initLendBidProcess(contractAddress, decimals);
  }
}

//-------------------------- Lend Bid Execute

window.lendExecutePopupCalculation = function lendExecutePopupCalculation(
  thisBid
) {
  var serviceFee = percentageOf(
    $("#borrowerFee").text(),
    thisBid.attr("price")
  );
  $("#execLendInterestRate").html(thisBid.attr("interest_Rate"));
  $("#execLendDuration").html(thisBid.attr("duration"));
  $("#execLendQuantity").html(thisBid.attr("qty") || 1);
  $("#executeLendBorrowerFee").html(
    mergeAmountSymbol(serviceFee, thisBid.attr("bidsymbol"))
  );
  var amt = parseFloat(thisBid.attr("price"));
  var totalPayAmt = plusNum(amt, serviceFee);
  $("#executeLendBidFinalAmt").html(numToString(amt - serviceFee));
  $("#executeLendBidFinalAmt").attr("payAmt", thisBid.attr("price"));
  $("#executeLendBidFinalAmt").attr("totalPayAmt", totalPayAmt);
  $("#executeLendBidFinalUsd").html(
    coinToUsd(amt - serviceFee, thisBid.attr("bidsymbol"))
  );
};

window.lendExecuteApproveFailed = function lendExecuteApproveFailed(errorMsg) {
  toastr.error(errorMsg);
  hideAll();
  $(".approveExecbidRetry").removeClass("hide");
  $(".approveBidStart").removeClass("hide");
};

window.lendExecuteAssetFailed = function lendExecuteAssetFailed(errorMsg) {
  toastr.error(errorMsg);
  hideAll();
  $(".approveExecLendBidDone").removeClass("hide");
  $(".acceptLendBidRetry").removeClass("hide");
};

window.saveLendExecute = async function saveLendExecute(
  collectionId,
  buyerAddress,
  lendId,
  bidId,
  transactionHash,
  quantity,
  transaction_lend_id,
  tokenId = 0
) {
  var request = $.ajax({
    url: "/collections/" + collectionId + "/lends/" + lendId + "/execute",
    type: "POST",
    async: false,
    data: {
      address: buyerAddress,
      bid_id: bidId,
      transaction_hash: transactionHash,
      token_id: tokenId,
      transaction_lend_id: transaction_lend_id,
      supply: quantity,
    },
    dataType: "script",
    success: function (respVal) {
      console.log(respVal);
    },
  });
};

window.lendExecuteAssetSuccess = async function lendExecuteAssetSuccess(
  collectionId,
  borrower,
  lendId,
  bidId,
  transactionHash,
  quantity,
  transaction_lend_id,
  tokenId
) {
  hideAll();
  $(".allDone").removeClass("hide");
  await saveLendExecute(
    collectionId,
    borrower,
    lendId,
    bidId,
    transactionHash,
    quantity,
    transaction_lend_id,
    tokenId
  );
};

window.lendExecuteAsset = async function lendExecuteAsset(
  borrower,
  lender,
  erc20Address,
  nftAddress,
  nftType,
  totalAmount,
  amount,
  tokenId,
  qty,
  loanDuration,
  interestRateDuration,
  decimals = 18,
  buyerSign,
  lendId,
  bidId,
  collectionId
) {
  try {
    var paymentAmt = roundNumber(mulBy(amount, 10 ** decimals), 0);
    var totalPaymentAmt = roundNumber(mulBy(totalAmount, 10 ** decimals), 0);
    var unitPrice = 1;

    const lendExecuteAsset = await fetchContract(
      lendContractAddress,
      "lending"
    );
    var nonce_value = await getContractSignNonce(collectionId, buyerSign);
    var account = await getCurrentAccount();
    var gasPrices = await gasPrice();

    var orderStruct = [
      borrower,
      lender,
      erc20Address,
      nftAddress,
      nftType,
      paymentAmt,
      totalPaymentAmt,
      tokenId,
      qty,
      loanDuration,
      convertInterestRate(interestRateDuration),
    ];
    console.log(orderStruct);

    var receipt = await lendExecuteAsset.inititateLend(
      orderStruct,
      splitSign(buyerSign, nonce_value),
      { from: account, gasLimit: defautGas, gasPrice: String(gasPrices) }
    );
    receipt = await receipt.wait();
    var transaction_lend_id = receipt.events
      .filter((r) => {
        return (
          r.topics[0] ==
          "0x5f20982d2196b477a67cf9eec22865b0093715235a896e9202a55d2acb85dba3"
        );
      })
      .map((l) => {
        return parseInt(l.args["loanId"]);
      });
    transaction_lend_id = transaction_lend_id[0];
    return window.lendExecuteAssetSuccess(
      collectionId,
      borrower,
      lendId,
      bidId,
      receipt.transactionHash,
      qty,
      transaction_lend_id,
      tokenId
    );
  } catch (err) {
    console.error(err);
    return window.lendExecuteAssetFailed(err["message"]);
  }
};

window.initLendExecuteAcceptProcess = function initLendExecuteAcceptProcess() {
  var contractAddress = $("#erc20ContractAddress").text();
  var paymentDetails = fetchCollectionDetails(
    $("#lendBidId").val(),
    contractAddress,
    "LendBid"
  );
  var amount = $("#executeLendBidFinalAmt").attr("payAmt");
  var totalAmount = $("#executeLendBidFinalAmt").attr("totalPayAmt");
  lendExecuteAsset(
    paymentDetails["owner_address"],
    paymentDetails["lender_address"],
    paymentDetails["pay_token_address"],
    paymentDetails["asset_address"],
    toNum(paymentDetails["asset_type"]),
    toNum(totalAmount),
    toNum(amount),
    paymentDetails["token_id"],
    paymentDetails["quantity"],
    paymentDetails["duration"],
    paymentDetails["interest_rate"],
    toNum(paymentDetails["pay_token_decimal"]),
    paymentDetails["buyer_sign"],
    paymentDetails["lend_id"],
    paymentDetails["bid_id"],
    paymentDetails["collection_id"]
  );
};

window.lendExecuteApproveSuccess = function lendExecuteApproveSuccess() {
  hideAll();
  $(".approveExecLendBidDone").removeClass("hide");
  $(".acceptLendBidProgress").removeClass("hide");
  initLendExecuteAcceptProcess();
};

window.approveLendNFT = async function approveLendNFT(
  sendBackTo = "LendExecute"
) {
  try {
    var sharedCollection = gon.collection_data["contract_shared"];
    var contractType = $("#contractType").text();
    var contractAddress = $("#contractAddress").text();
    var account = await getCurrentAccount();
    const approveNFT = await fetchContract(
      contractAddress,
      contractType,
      sharedCollection
    );
    var isApproved = await approveNFT.isApprovedForAll(
      account,
      lendContractAddress
    );

    var gasPrices = await gasPrice();
    if (!isApproved) {
      var receipt = await approveNFT.setApprovalForAll(
        lendContractAddress,
        true,
        { from: account, gasLimit: defautGas, gasPrice: String(gasPrices) }
      );
      receipt = await receipt.wait();
    }
    if (sendBackTo == "putonLend") {
      savePutOnLend();
    } else {
      return window.lendExecuteApproveSuccess();
    }
  } catch (err) {
    console.error(err);
    return window.lendExecuteApproveFailed(err["message"]);
  }
};

window.initLendExecuteApproveProcess =
  function initLendExecuteApproveProcess() {
    approveLendNFT("LendExecute");
  };

//-------------------------- Pay lending

window.signMetadataForLend = function signMetadataForLend(
  collectionId,
  lendContractAddress,
  borrower,
  tokenId,
  transactionLendId
) {
  var sign;
  $.ajax({
    url: `/collections/${collectionId}/sign_metadata_for_lend`,
    type: "POST",
    async: false,
    data: {
      lend_contract_address: lendContractAddress,
      borrower_address: borrower,
      token_id: tokenId,
      transaction_lend_id: transactionLendId,
    },
    dataType: "json",
  })
    .done(function (msg) {
      console.log(msg);
      sign = { sign: msg["signature"], nonce: msg["nonce"] };
    })
    .fail(function (jqXHR, textStatus) {
      console.log("Bidding failed. Please contact support");
    });
  return sign;
};

function saveLendRepayment(receipt, collectionId, lend_id, quantity) {
  hideAll();
  $(".approvePayLendDone").removeClass("hide");
  $(".signPayLendDone").removeClass("hide");

  $.ajax({
    url: `/collections/${collectionId}/lends/` + lend_id + `/repayment`,
    type: "POST",
    async: false,
    data: { transaction_hash: receipt.transactionHash, supply: quantity },
    dataType: "script",
  })
    .done(function (msg) {
      console.log(msg, "Lend repayment successful, collection claimed!");
    })
    .fail(function (jqXHR, textStatus) {
      console.log("Lend repayment failed");
    });
}

window.lendRepaymentFailed = function lendRepaymentFailed(errorMsg) {
  hideAll();
  $(".convertDone").removeClass("hide");
  $(".approvePayLendDone").removeClass("hide");
  $(".signPayLendRetry").removeClass("hide");
};

window.initLendRepayment = async function initLendRepayment() {
  try {
    hideAll();
    $(".approvePayLendDone").removeClass("hide");
    $(".signPayLendProgress").removeClass("hide");

    var $erc20 = $("#pay_lend_bid_erc20");
    var contractAddress = $erc20.data("token-address");
    var collectionId = $("#collection_id").val();
    var details = fetchCollectionDetails(null, contractAddress, "LendPay");
    const signer = gon.provider.getSigner();
    var borrower = await signer.getAddress();
    var gasPrices = await gasPrice();
    var nonce_value = await getContractSignNonce(
      collectionId,
      details["buyer_sign"]
    );
    const initLendRepayment = await fetchContract(
      lendContractAddress,
      "lending"
    );
    var ownerSign = await signMetadataForLend(
      collectionId,
      lendContractAddress,
      borrower,
      details.token_id,
      details.transaction_lend_id
    );
    await saveContractNonceValue(collectionId, ownerSign);
    var signStruct = splitSign(ownerSign["sign"], ownerSign["nonce"]);

    var receipt = await initLendRepayment.loanRepayment(
      details.transaction_lend_id,
      signStruct,
      { from: borrower, gasLimit: defautGas, gasPrice: String(gasPrices) }
    );
    receipt = await receipt.wait();
    saveLendRepayment(receipt, collectionId, details.lend_id, details.quantity);
  } catch (err) {
    console.error(err);

    return window.lendRepaymentFailed(err["message"]);
  }
};

window.erc20TokenLendPayApproveFailed = function erc20TokenLendPayApproveFailed(
  errorMsg
) {
  toastr.error(errorMsg);
  hideAll();
  $(".convertDone").removeClass("hide");
  $(".approvePayLendRetry").removeClass("hide");
  $(".signPayLendStart").removeClass("hide");
};

window.approveERC20TokenLendPaySuccess =
  function approveERC20TokenLendPaySuccess(transactionHash, contractAddress) {
    initLendRepayment();
  };

window.initLendRepaymentApproveProcess =
  function initLendRepaymentApproveProcess() {
    hideAll();
    $(".convertDone").removeClass("hide");
    $(".approvePayLendProgress").removeClass("hide");
    $(".signPayLendStart").removeClass("hide");
    showMagnificPopup("#placeLendPay");

    var $erc20 = $("#pay_lend_bid_erc20");
    var contractAddress = $erc20.data("token-address");
    var decimals = $erc20.data("decimals");

    erc20TokenLendContractApprove(
      contractAddress,
      "erc20",
      $("#pay-lend-total-amount").attr("payAmt"),
      decimals,
      "LendPay"
    );
  };

window.payLendConvertSuccess = function payLendConvertSuccess(transactionHash) {
  initLendRepaymentApproveProcess();
};

window.initLendRepaymentProcess = function initLendRepaymentProcess() {
  var $erc20 = $("#pay_lend_bid_erc20");
  var curErc20Balance = $("#erc20_balance").text();
  var totalAmt = $("#pay-lend-total-amount").attr("payAmt");
  var symbol = $erc20.data("symbol");
  var contractAddress = $erc20.data("token-address");
  var contractDecimal = $erc20.data("decimals");
  var ethBalance = parseFloat($("#current-pay-lend-balance").text());
  if (isGreaterThanOrEqualTo(curErc20Balance, totalAmt)) {
    console.log("ONe");
    $(".convertEth").addClass("hide");
    initLendRepaymentApproveProcess();
  } else if (
    symbol === gon.tokenSymbol &&
    isGreaterThanOrEqualTo(ethBalance, totalAmt)
  ) {
    console.log("Two");
    convertMaticToWmatic(totalAmt - curErc20Balance, "LendPay");
  } else {
    console.log("THree");
    $("#biddingForm :input").prop("disabled", false);
    $.magnificPopup.close();

    return toastr.error("Not enough balance");
  }
};

async function lendRepaymentCalculation() {
  var $erc20 = $("#pay_lend_bid_erc20");
  var sym = $erc20.data("symbol");
  var contractAddress = $erc20.data("token-address");
  var decimals = $erc20.data("decimals");
  var duration = $erc20.data("duration");
  var lending_days = parseInt($erc20.data("lending-days")) + 1;
  var interest_rate =
    parseInt($erc20.data("interest-rate")) / $erc20.data("duration");
  var price = parseFloat($erc20.data("amount"));
  var interest_to_pay = lending_days * percentageOf(interest_rate, price);
  var payAmt = price + interest_to_pay;
  // var serviceFee = percentageOf($('#borrowerFee').text(), price);
  // var totalAmt = plusNum(payAmt, serviceFee);
  var assetBalance = (await tokenBalance(contractAddress, decimals)) || 0;
  $("#erc20_balance").text(assetBalance);
  $("#pay-lend-asset-balance").text(mergeAmountSymbol(assetBalance, sym));
  // $("#pay-lend-service-fee").html(mergeAmountSymbol(serviceFee, sym));
  $("#pay-lend-days-took").html(lending_days);
  $("#pay-lend-interest").text(mergeAmountSymbol(interest_to_pay, sym));
  $("#pay-lend-total-amount").html(mergeAmountSymbol(payAmt, sym));
  $("#pay-lend-total-amount").attr("payAmt", payAmt);
  $("#pay-lend-total-amount").attr("symbol", sym);
  $("#pay-lend-total-amount-usd").html(coinToUsd(payAmt, sym));
  $(".lend-pay-validate").removeClass("disabledLink");
}

//-------------------------- Lend overdue claim

function saveLendOverdueClaim(receipt, collectionId, lend_id, quantity) {
  hideAll();
  $(".signOverdueLendDone").removeClass("hide");
  $.ajax({
    url:
      `/collections/${collectionId}/lends/` + lend_id + `/lend_overdue_claim`,
    type: "POST",
    async: false,
    data: { transaction_hash: receipt.transactionHash, supply: quantity },
    dataType: "script",
  })
    .done(function (msg) {
      console.log("Lend collection claimed successful");
    })
    .fail(function (jqXHR, textStatus) {
      console.log("Lend repayment failed");
    });
}

window.loanOverdueFailed = function loanOverdueFailed(errorMsg) {
  toastr.error(errorMsg);
  hideAll();
  $(".signOverdueLendRetry").removeClass("hide");
};

window.initloanOverdue = async function initloanOverdue() {
  try {
    hideAll();
    $(".signOverdueLendProgress").removeClass("hide");
    var $erc20 = $("#overdue_lend_bid_erc20");
    var contractAddress = $erc20.data("token-address");
    var collectionId = $("#collection_id").val();
    var details = fetchCollectionDetails(null, contractAddress, "LendOverdue");
    const signer = gon.provider.getSigner();
    var lender = await signer.getAddress();

    var gasPrices = await gasPrice();
    var nonce_value = await getContractSignNonce(
      collectionId,
      details["buyer_sign"]
    );
    const initloanOverdue = await fetchContract(lendContractAddress, "lending");
    var ownerSign = await signMetadataForLend(
      collectionId,
      lendContractAddress,
      details.owner_address,
      details.token_id,
      details.transaction_lend_id
    );
    await saveContractNonceValue(collectionId, ownerSign);
    var signStruct = splitSign(ownerSign["sign"], ownerSign["nonce"]);

    var receipt = await initloanOverdue.loanOverdue(
      details.transaction_lend_id,
      signStruct,
      { from: lender, gasLimit: defautGas, gasPrice: String(gasPrices) }
    );
    receipt = await receipt.wait();
    saveLendOverdueClaim(
      receipt,
      collectionId,
      details.lend_id,
      details.quantity
    );
  } catch (err) {
    console.error(err);
    return window.loanOverdueFailed(err["message"]);
  }
};

function initLendOverdue() {
  hideAll();
  $(".approveOverdueLendProgress").removeClass("hide");
  $(".signOverdueLendStart").removeClass("hide");

  showMagnificPopup("#lendOverdueStepsModal");

  var $erc20 = $("#overdue_lend_bid_erc20");
  var contractAddress = $erc20.data("token-address");
  var decimals = $erc20.data("decimals");
  initloanOverdue();
}

//-------------------------- jquery dom events

$(document).ready(function () {
  $("#btn-puton-lend").on("click", function (e) {
    clearToastr();
    putOnLendValidation();
  });

  $(".lend-bid-validate").on("click", function (e) {
    clearToastr();
    e.preventDefault();
    lendBidValidation();
  });

  $(document).on("click", ".acceptLendBidRetry", function () {
    hideAll();
    $(".approveExecLendBidDone").removeClass("hide");
    $(".acceptLendBidProgress").removeClass("hide");
    initLendExecuteAcceptProcess();
  });

  $(document).on("click", ".approveLendBidRetry", function () {
    var contractAddress = $("#bid_currency option:selected, this").attr(
      "address"
    );
    lendBidApproveProcess(contractAddress);
  });

  $(document).on("click", ".signLendBidRetry", function () {
    var contractAddress = $("#lend_bid_currency option:selected, this").attr(
      "address"
    );
    initLendBidSignProcess(contractAddress);
  });

  $("#lend_bid_amt, #lend_bid_currency, #lend_bid_quantity").on(
    "input",
    function () {
      lendBidPopupCalculation($("#lenderFee").text());
    }
  );

  $(document).on("click", ".lend-execute-validate", function (e) {
    clearToastr();
    e.preventDefault();
    show_modal("#lendExecuteStepsModal");
    initLendExecuteApproveProcess();
  });

  $(document).on("click", ".btn-lend-execute", function (e) {
    clearToastr();
    $(".lendBidExecDetail").text($(this).attr("bidDetail"));
    $("#lendBidByUser").text($(this).attr("bidUser"));
    $(".executeLendBidSymbol").text($(this).attr("bidSymbol"));
    $("#contractAddress").text($(this).attr("contractAddress"));
    $("#erc20ContractAddress").text($(this).attr("erc20ContractAddress"));
    $("#lendBidId").val($(this).attr("bidId"));
    lendExecutePopupCalculation($(this));
    show_modal("#lendExecuteModal");
  });

  //-------------------------- Lend Repayment
  $(".show-pay-lend-modal").on("click", function () {
    $(".lend-pay-validate").addClass("disabledLink");
    lendRepaymentCalculation();
  });

  $(".lend-pay-validate").on("click", function (e) {
    e.preventDefault();
    initLendRepaymentProcess();
  });

  $(document).on("click", ".signPayLendRetry", function () {
    initLendRepayment();
  });

  $(document).on("click", ".approvePayLendRetry", function () {
    initLendRepaymentApproveProcess();
  });

  //-------------------------- Lend Overdue claim

  $(document).on("click", ".btn-lend-overdue", function () {
    initLendOverdue();
  });

  $(document).on("click", ".signOverdueLendRetry", function () {
    initLendOverdue();
  });

  //------------------------ form field validations
  $("#lend_interest_rate").on("input", function () {
    if (this.value.match(/^\d+\.\d{0,2}/)) {
      this.value = this.value.match(/^\d+\.\d{0,2}/);
    }

    if (this.value > 100) {
      this.value = this.value.match(/^\d{0,2}/);
    } else if (this.value < 0) {
      this.value = 0;
    }
  });
});
