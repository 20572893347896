import axios from "axios";
import { ethers } from "ethers";

const tokenURIPrefix = gon.tokenURIPrefix;
const transferProxyContractAddress = gon.transferProxyContractAddress;
const wmaticAddress = gon.wmaticAddress;
const tradeContractAddress = gon.tradeContractAddress;
const factoryContractAddressFor721 = gon.factoryContractAddressFor721;
const factoryContractAddressFor1155 = gon.factoryContractAddressFor1155;
const sessionWallet = gon.wallet;
let selectedWallet;
const sessionAddress = gon.address;
const rpcTarget = gon.rpc; 

let account;
let signer;
let provider;

const web3authSdk = window.Modal;
// const chainConfig =  {
//   chainNamespace: "eip155",
//   chainId: gon.chainIdHex,
//   rpcTarget,
// }
const displayName = "Brilliance";
const clientId = gon.web3auth.client_id;

const chainConfig = {
  chainId: gon.chainIdHex, // Please use 0x1 for ETH Mainnet, 0x89 for Polygon Mainnet
  rpcTarget,
  chainNamespace: Base.CHAIN_NAMESPACES.EIP155,
  displayName,
  blockExplorerUrl: "https://polygonscan.com/",
  ticker: "MATIC",
  tickerName: "MATIC",
  logo: "https://cryptologos.cc/logos/polygon-matic-logo.png",
};
const web3AuthNetwork = Base.WEB3AUTH_NETWORK.SAPPHIRE_MAINNET;
const privateKeyProvider = new EthereumProvider.EthereumPrivateKeyProvider({
  config: { chainConfig },
});
const web3AuthOptions = {
  clientId,
  web3AuthNetwork,
  privateKeyProvider: privateKeyProvider,
  sessionTime: 86400, // 1 day
  // useCoreKitKey: true,
};

let web3AuthInstance = new Modal.Web3Auth(web3AuthOptions);
// const openloginAdapter = new OpenloginAdapter.OpenloginAdapter({
//   loginSettings: {
//     mfaLevel: "optional",
//   },
//   whiteLabel: {
//     name: "Brilliance",
//     defaultLanguage: "en",
//     dark: true, // whether to enable dark mode. defaultValue: false
//   },
//   adapterSettings: {
//     // loginConfig: {
//     //   jwt: {
//     //     name: "LinkedIn",
//     //     verifier: "Brilliance-LinkedIn-Testnet",
//     //     typeOfLogin: "jwt",
//     //     clientId: "N8mUbRzQkK8vmi2TLzpFXKcRp6SgyZx8",
//     //   },
//     //   google: {
//     //     name: "Google",
//     //     verifier: "Brilliance-google-testnet",
//     //     typeOfLogin: "google",
//     //     clientId:
//     //       "33305797874-8d7jkuobvvt5fj2ai96n1r5dfhg31mb0.apps.googleusercontent.com",
//     //   },
//     //   facebook: {
//     //     name: "Facebook",
//     //     verifier: "Brilliance-Facebook-Testnet",
//     //     typeOfLogin: "facebook",
//     //     clientId: "886157315873873",
//     //   },
//     // },
//     uxMode: "popup", // "redirect" | "popup"
//     mfaSettings: {
//       deviceShareFactor: {
//         enable: true,
//         priority: 1,
//         mandatory: true,
//       },
//       backUpShareFactor: {
//         enable: true,
//         priority: 2,
//         mandatory: false,
//       },
//       socialBackupFactor: {
//         enable: true,
//         priority: 3,
//         mandatory: false,
//       },
//       passwordFactor: {
//         enable: true,
//         priority: 4,
//         mandatory: false,
//       },
//     },
//   },
// });

const torusWalletAdapter = new TorusEvmAdapter.TorusWalletAdapter({
  adapterSettings: {
    buttonPosition: "bottom-left",
    whiteLabel: {
      name: "Brilliance",
      defaultLanguage: "en",
      dark: true, // whether to enable dark mode. defaultValue: false
    },
  },
  // loginSettings: {
  //   verifier: "google",
  // },
  chainConfig,
  clientId,
  sessionTime: 3600, // 1 hour in seconds
  web3AuthNetwork,
});

// const walletServicesPlugin = new WalletServicesPlugin.WalletServicesPlugin({
//   walletInitOptions: {
//     whiteLabel: {
//       showWidgetButton: true,
//       buttonPosition: "bottom-left",
//     },
//   },
// });
// it will add/update  the torus-evm adapter in to web3auth class

window.web3AuthInstance = web3AuthInstance;
async function loadWeb3() {
  const wallet = selectedWallet ?? sessionWallet;
  if (!wallet) {
    toastr.error("Please select wallet.");
  }

  if (wallet == "metamask") {
    gon.provider = provider = new ethers.providers.Web3Provider(
      window.ethereum
    );
    await provider.send("eth_requestAccounts", []);
  } else {
    if (web3AuthInstance.status == "not_ready") {
      // web3AuthInstance.configureAdapter(openloginAdapter);
      // web3AuthInstance.addPlugin(walletServicesPlugin);
      const metamaskAdapter = new MetamaskAdapter.MetamaskAdapter(
        web3AuthOptions
      );
      web3AuthInstance.configureAdapter(metamaskAdapter);
      web3AuthInstance.configureAdapter(torusWalletAdapter);
      await web3AuthInstance.initModal();
    }
    $.magnificPopup.close();
    if (web3AuthInstance.status == "ready") {
      await web3AuthInstance.connect();
    }

    web3AuthInstance.on("disconnected", () => {
      disconnect();
    });
    gon.provider = provider = new ethers.providers.Web3Provider(
      web3AuthInstance.provider
    );
  }
  providerEvents();
  signer = provider.getSigner();
  return signer.getAddress();
}

async function getAccounts() {
  try {
    const signer = provider.getSigner();
    const account = await signer.getAddress();
    return account;
  } catch (e) {}
}

function getInfuraId() {
  const url = new URL(rpcUrl);
  const path = url.pathname.split("/");
  return path[path.length - 1] ?? "";
}

async function createUserSession(address, balance, destroy_session) {
  const config = {
    headers: {
      "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const data = {
    address,
    balance,
    wallet: selectedWallet,
    destroy_session,
  }
  
  const resp = await axios
    .post(
      `/sessions`,
      {...data}
      ,
      config
    )
    .then((response) => {
      return resp;
    })
    .catch((err) => {});
  return resp;
}

async function destroyUserSession(address) {
  const config = {
    data: {},
    headers: {
      "X-CSRF-TOKEN": $('[name="csrf-token"]')[0].content,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const resp = axios
    .delete(`/sessions/${address}`, config)
    .then((response) => response)
    .catch((err) => {});
  localStorage.clear();
  return resp;
}

function updateTokenId(tokenId, collectionId) {
  var request = $.ajax({
    url: `/collections/${collectionId}/update_token_id`,
    async: false,
    type: "POST",
    data: { tokenId: tokenId, collectionId: collectionId },
    dataType: "script",
  });
  request.done(function (msg) {});
  request.fail(function (jqXHR, textStatus) {});
}

function createContract(
  name,
  symbol,
  contract_address,
  contractType,
  collectionId
) {
  var request = $.ajax({
    url: "/users/create_contract",
    async: false,
    type: "POST",
    data: {
      name: name,
      symbol: symbol,
      contract_address: contract_address,
      contract_type: contractType,
      collection_id: collectionId,
    },
    dataType: "script",
  });
  request.done(function (msg) {});
  request.fail(function (jqXHR, textStatus) {});
}

function updateCollectionBuy(collectionId, quantity, transactionHash) {
  var request = $.ajax({
    url: "/collections/" + collectionId + "/buy",
    type: "POST",
    async: false,
    data: { quantity: quantity, transaction_hash: transactionHash },
    dataType: "script",
    success: function (respVal) {},
  });
}

function updateCollectionSell(
  collectionId,
  buyerAddress,
  bidId,
  transactionHash
) {
  var request = $.ajax({
    url: "/collections/" + collectionId + "/sell",
    type: "POST",
    async: false,
    data: {
      address: buyerAddress,
      bid_id: bidId,
      transaction_hash: transactionHash,
    },
    dataType: "script",
    success: function (respVal) {},
  });
}

function updateOwnerTransfer(
  collectionId,
  recipientAddress,
  transactionHash,
  supply
) {
  var request = $.ajax({
    url: "/collections/" + collectionId + "/owner_transfer",
    type: "POST",
    async: false,
    data: {
      recipient_address: recipientAddress,
      transaction_hash: transactionHash,
      supply: supply,
    },
    dataType: "script",
    success: function (respVal) {},
  });
}

function updateBurn(collectionId, transactionHash, supply) {
  var request = $.ajax({
    url: "/collections/" + collectionId + "/burn",
    type: "POST",
    async: false,
    data: { transaction_hash: transactionHash, supply: supply },
    dataType: "script",
    success: function (respVal) {},
  });
}

async function isValidUser(address, token, wallet) {
  const config = {
    headers: {
      "X-CSRF-TOKEN": token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const resp = await axios
    .get(
      `/sessions/valid_user`,
      { params: { address, authenticity_token: token, wallet } },
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {});
  return resp;
}

function placeBid(collectionId, sign, quantity, bidDetails) {
  var request = $.ajax({
    url: `/collections/${collectionId}/bid`,
    type: "POST",
    async: false,
    data: { sign: sign, quantity: quantity, details: bidDetails },
    dataType: "script",
  });
  request.done(function (msg) {});
  request.fail(function (jqXHR, textStatus) {});
}

function signMetadataHash(collectionId, contractAddress) {
  var sign;
  var request = $.ajax({
    url: `/collections/${collectionId}/sign_metadata_hash`,
    type: "POST",
    async: false,
    data: { contract_address: contractAddress },
    dataType: "json",
  });
  request.done(function (msg) {
    sign = { sign: msg["signature"], nonce: msg["nonce"] };
  });
  request.fail(function (jqXHR, textStatus) {});
  return sign;
}

function updateSignature(collectionId, sign) {
  var request = $.ajax({
    url: `/collections/${collectionId}/sign_fixed_price`,
    type: "POST",
    async: false,
    data: { sign: sign },
    dataType: "script",
  });
  request.done(function (msg) {});
  request.fail(function (jqXHR, textStatus) {});
}

window.saveContractNonceValue = function saveContractNonceValue(
  collectionId,
  sign
) {
  var request = $.ajax({
    url: `/collections/${collectionId}/save_contract_nonce_value`,
    async: false,
    type: "POST",
    data: { signature: sign },
    dataType: "script",
  });
  request.done(function (msg) {});
  request.fail(function (jqXHR, textStatus) {});
};

window.getNonceValue = function getNonceValue(collectionId) {
  var nonce;
  var request = $.ajax({
    url: `/collections/${collectionId}/get_nonce_value`,
    type: "POST",
    async: false,
    data: {},
    dataType: "json",
  });
  request.done(function (data) {
    nonce = data["nonce"];
  });
  request.fail(function (jqXHR, textStatus) {});
  return nonce;
};

window.saveNonceValue = function saveNonceValue(collectionId, sign, nonce) {
  var request = $.ajax({
    url: `/collections/${collectionId}/save_nonce_value`,
    type: "POST",
    async: false,
    data: { sign: sign, nonce: nonce },
    dataType: "script",
  });
};

window.getContractSignNonce = function (collectionId, sign) {
  var nonce;
  var request = $.ajax({
    url: `/collections/${collectionId}/get_contract_sign_nonce`,
    type: "POST",
    async: false,
    data: { sign: sign },
    dataType: "json",
  });
  request.done(function (data) {
    nonce = data["nonce"];
  });
  return nonce;
};

if (provider) {
  provider.on("networkChanged", function () {
    window.checkNetwork();
  });
}

window.getContractABIAndBytecode = function (
  contractAddress,
  type,
  shared = true
) {
  var res;
  var request = $.ajax({
    async: false,
    url: "/contract_abi",
    type: "GET",
    data: { contract_address: contractAddress, type: type, shared: shared },
    dataType: "json",
  });

  request.done(function (msg) {
    res = msg;
  });
  return res;
};

window.splitSign = function splitSign(sign, nonce) {
  let sig = ethers.utils.splitSignature(sign);
  return [sig.v, sig.r, sig.s, nonce];
};

window.getContract = async function getContract(
  contractAddress,
  type,
  shared = true
) {
  var res = getContractABIAndBytecode(contractAddress, type, shared);
  var contractObj = new ethers.Contract(
    contractAddress,
    res["compiled_contract_details"]["abi"],
    provider
  );
  return contractObj;
};

window.createCollectible721 = async function (
  contractAddress,
  tokenURI,
  royaltyFee,
  collectionId,
  sharedCollection
) {
  try {
    var account = await getCurrentAccount();
    const contract721 = await fetchContract(
      contractAddress,
      "nft721",
      sharedCollection
    );
    var gasPrices = await gasPrice();
    if (sharedCollection) {
      var sign = await signMetadataHash(collectionId, contractAddress);
      await saveContractNonceValue(collectionId, sign);
      var signStruct = splitSign(sign["sign"], sign["nonce"]);
      var txn = await contract721.createCollectible(
        tokenURI,
        royaltyFee,
        signStruct,
        {
          from: account,
          gasLimit: 516883,
          gasPrice: String(gasPrices),
        }
      );
    } else {
      var txn = await contract721.createCollectible(tokenURI, royaltyFee, {
        from: account,
        gasLimit: 516883,
        gasPrice: String(gasPrices),
      });
    }
    txn = await txn.wait();
    var tokenId = parseInt(txn.events[0].topics[3]);
    await updateTokenId(tokenId, collectionId);
    return window.collectionMintSuccess(collectionId);
  } catch (err) {
    return window.collectionMintFailed(err["message"]);
  }
};

window.createCollectible1155 = async function createCollectible1155(
  contractAddress,
  supply,
  tokenURI,
  royaltyFee,
  collectionId,
  sharedCollection
) {
  try {
    var account = await getCurrentAccount();
    const contract1155 = await fetchContract(
      contractAddress,
      "nft1155",
      sharedCollection
    );
    var gasPrices = await gasPrice();
    if (sharedCollection) {
      var sign = await signMetadataHash(collectionId, contractAddress);
      await saveContractNonceValue(collectionId, sign);
      var signStruct = splitSign(sign["sign"], sign["nonce"]);
      var txn = await contract1155.mint(
        tokenURI,
        supply,
        royaltyFee,
        signStruct,
        {
          from: account,
          gasLimit: 516883,
          gasPrice: String(gasPrices),
        }
      );
    } else {
      var txn = await contract1155.mint(tokenURI, supply, royaltyFee, {
        from: account,
        gasLimit: 516883,
        gasPrice: String(gasPrices),
      });
    }
    txn = await txn.wait();
    var tokenId = parseInt(txn.events[0].data.slice(0, 66));
    await updateTokenId(tokenId, collectionId);
    return window.collectionMintSuccess(collectionId);
  } catch (err) {
    return window.collectionMintFailed(err["message"]);
  }
};

function getRandom(address) {
  let value = Date.now() + Math.floor(Math.random() * 10 ** 10 + 1);
  var hex = value.toString(16);
  hex = hex + address.slice(2);
  return `0x${"0".repeat(64 - hex.length)}${hex}`;
}

window.deployContract = async function deployContract(
  abi,
  bytecode,
  name,
  symbol,
  contractType,
  collectionId
) {
  let contractDeploy;
  var contractNFT;
  let contractAddress;
  try {
    var sign = provider.getSigner();
    if (contractType == "nft721") {
      contractNFT = await new ethers.Contract(
        factoryContractAddressFor721,
        abi,
        provider
      );
    } else if (contractType == "nft1155") {
      contractNFT = await new ethers.Contract(
        factoryContractAddressFor1155,
        abi,
        provider
      );
    }

    contractDeploy = contractNFT.connect(sign);
    var account = await getAccounts();
    var salt = getRandom(account);
    var contract = await contractDeploy.deploy(
      salt,
      name,
      symbol,
      tokenURIPrefix
    );
    var receipt = await contract.wait();

    contractAddress = receipt.events[2].args["contractAddress"];
    $("#nft_contract_address").val(contractAddress);
    createContract(name, symbol, contractAddress, contractType, collectionId);
    window.contractDeploySuccess(contractAddress, contractType);
  } catch (err) {
    window.contractDeployFailed(err["message"]);
  }
};

window.approveNFT = async function (
  contractType,
  contractAddress,
  sharedCollection,
  sendBackTo = "collection"
) {
  try {
    var account = await getCurrentAccount();
    const approveNFT = await fetchContract(
      contractAddress,
      contractType,
      sharedCollection
    );
    var isApproved = await approveNFT.isApprovedForAll(
      account,
      transferProxyContractAddress
    );
    if (!isApproved) {
      var receipt = await approveNFT.setApprovalForAll(
        transferProxyContractAddress,
        true,
        { from: account }
      );
      receipt = await receipt.wait();
    }
    if (sendBackTo == "executeBid") {
      return window.approveBidSuccess();
    } else {
      return window.collectionApproveSuccess(contractType);
    }
  } catch (err) {
    if (sendBackTo == "executeBid") {
      return window.approveBidFailed(err["message"]);
    } else {
      return window.collectionApproveFailed(err["message"]);
    }
  }
};

window.approveResaleNFT = async function approveResaleNFT(
  contractType,
  contractAddress,
  sharedCollection
) {
  try {
    var account = await getCurrentAccount();
    const resaleNFT = await fetchContract(
      contractAddress,
      contractType,
      sharedCollection
    );
    var isApproved = await resaleNFT.isApprovedForAll(
      account,
      transferProxyContractAddress
    );
    var gasPrices = await gasPrice();
    if (!isApproved) {
      var receipt = await resaleNFT.setApprovalForAll(
        transferProxyContractAddress,
        true,
        { from: account, gasLimit: 516883, gasPrice: String(gasPrices) }
      );
      receipt = await receipt.wait();
    }
    return window.approveResaleSuccess(contractType);
  } catch (err) {
    return window.approveResaleFailed(err["message"]);
  }
};

window.fetchContract = async function fetchContract(
  contractAddress,
  type,
  shared = true
) {
  var compiledContractDetails = getContractABIAndBytecode(
    contractAddress,
    type,
    shared
  );
  var abi = compiledContractDetails["compiled_contract_details"]["abi"];
  var obj = new ethers.Contract(contractAddress, abi, provider);
  var connection = obj.connect(signer);
  return connection;
};

//TODO: OPTIMIZE
window.isApprovedNFT = async function isApprovedNFT(
  contractType,
  contractAddress
) {
  try {
    const approvednft = await fetchContract(contractAddress, contractType);
    var account = await getAccounts();
    var isApproved = await approvednft.isApprovedForAll(
      account,
      transferProxyContractAddress
    );
    return isApproved;
  } catch (err) {}
};

window.burnNFT = async function burnNFT(
  contractType,
  contractAddress,
  tokenId,
  supply = 1,
  collectionId,
  sharedCollection
) {
  try {
    const burnnft = await fetchContract(
      contractAddress,
      contractType,
      sharedCollection
    );
    var account = await getAccounts();
    if (contractType == "nft721") {
      var receipt = await burnnft.burn(tokenId, { from: account });
    } else if (contractType == "nft1155") {
      var receipt = await burnnft.burn(tokenId, supply, { from: account });
    }
    receipt = await receipt.wait();
    await updateBurn(collectionId, receipt.transactionHash, supply);
    return window.burnSuccess(receipt.transactionHash);
  } catch (err) {
    return window.burnFailed(err["message"]);
  }
};

window.directTransferNFT = async function directTransferNFT(
  contractType,
  contractAddress,
  recipientAddress,
  tokenId,
  supply = 1,
  shared,
  collectionId
) {
  try {
    const transfernft = await fetchContract(
      contractAddress,
      contractType,
      shared
    );
    var account = await getCurrentAccount();
    if (contractType == "nft721") {
      // var receipt = await transfernft.safeTransferFrom(account, recipientAddress, tokenId, {from: account});
      var receipt = await transfernft[
        "safeTransferFrom(address,address,uint256)"
      ](account, recipientAddress, tokenId);
    } else if (contractType == "nft1155") {
      // TODO: Analyse and use proper one in future
      var tempData =
        "0x6d6168616d000000000000000000000000000000000000000000000000000000";
      var receipt = await transfernft.safeTransferFrom(
        account,
        recipientAddress,
        tokenId,
        supply,
        tempData,
        { from: account }
      );
    }
    receipt = await receipt.wait();
    await updateOwnerTransfer(
      collectionId,
      recipientAddress,
      receipt.transactionHash,
      supply
    );
    return window.directTransferSuccess(receipt.transactionHash, collectionId);
  } catch (err) {
    return window.directTransferFailed(err["message"]);
  }
};

window.approveERC20 = async function approveERC20(
  contractAddress,
  contractType,
  amount,
  decimals = 18,
  sendBackTo = "Bid"
) {
  try {
    amount = roundNumber(mulBy(amount, 10 ** decimals), 0);
    const approveERC = await fetchContract(
      contractAddress,
      contractType,
      gon.collection_data["contract_shared"]
    );
    var account = await getAccounts();
    var balance = await approveERC.allowance(
      account,
      transferProxyContractAddress
    );
    amount = BigInt(parseInt(balance) + parseInt(amount)).toString();
    var gasPrices = await gasPrice();
    var receipt = await approveERC.approve(
      transferProxyContractAddress,
      amount,
      { from: account, gasLimit: 516883, gasPrice: String(gasPrices) }
    );
    if (sendBackTo == "Buy") {
      return window.buyApproveSuccess(receipt.transactionHash, contractAddress);
    } else if (sendBackTo == "Bid") {
      return window.bidApproveSuccess(receipt.transactionHash, contractAddress);
    } else {
      return window.lendBidApproveSuccess(
        receipt.transactionHash,
        contractAddress
      );
    }
  } catch (err) {
    if (sendBackTo == "Buy") {
      return window.buyApproveFailed(err["message"]);
    } else if (sendBackTo == "Bid") {
      return window.bidApproveFailed(err["message"]);
    } else {
      return window.lendBidFailed(err["message"]);
    }
  }
};

window.approvedTokenBalance = async function approvedTokenBalance(
  contractAddress
) {
  var appTokenBal = await fetchContract(contractAddress, "erc20", false);
  var account = await getAccounts();
  var balance = await appTokenBal.allowance(
    account,
    transferProxyContractAddress
  );
  return balance;
};

window.convertWMATIC = async function convertWMATIC(
  amount,
  sendBackTo = "Bid",
  decimals = 18
) {
  try {
    amount = roundNumber(mulBy(amount, 10 ** decimals), 0);
    const convertWMATIC = await fetchContract(wmaticAddress, "erc20");
    var account = await getAccounts();
    var gasPrices = await gasPrice();
    var receipt = await convertWMATIC.deposit({
      from: account,
      value: amount,
      gasLimit: 516883,
      gasPrice: String(gasPrices),
    });
    receipt = await receipt.wait();
    if (sendBackTo == "Buy") {
      return window.buyConvertSuccess(receipt.transactionHash);
    } else if (sendBackTo == "Bid") {
      return window.bidConvertSuccess(receipt.transactionHash);
    } else if (sendBackTo == "Lend") {
      return window.lendBidConvertSuccess(receipt.transactionHash);
    } else {
      return window.payLendConvertSuccess(receipt.transactionHash);
    }
  } catch (err) {
    if (sendBackTo == "Buy") {
      return window.bidConvertFailed(err["message"]);
    } else {
      return window.bidConvertFailed(err["message"]);
    }
  }
};

window.updateBuyerServiceFee = async function updateBuyerServiceFee(
  buyerFeePermille
) {
  try {
    const buyerServiceFee = await fetchContract(tradeContractAddress, "trade");
    var account = await getAccounts();
    var gasPrices = await gasPrice();
    var receipt = await buyerServiceFee.setBuyerServiceFee(
      buyerFeePermille * 10,
      { from: account, gasLimit: 516883, gasPrice: String(gasPrices) }
    );
    receipt = await receipt.wait();
    if (String(receipt.status) === "true") {
      $("form#fee_form").submit();
      $("div.loading-gif.displayInMiddle").hide();
    }
  } catch (err) {
    return false;
  }
};

window.updateSellerServiceFee = async function (sellerFeePermille) {
  try {
    const sellerServiceFee = await fetchContract(tradeContractAddress, "trade");
    var account = await getCurrentAccount();
    var gasPrices = await gasPrice();
    var receipt = await sellerServiceFee.setSellerServiceFee(
      sellerFeePermille * 10,
      { from: account, gasLimit: 516883, gasPrice: String(gasPrices) }
    );
    receipt = await receipt.wait();
    if (String(receipt.status) === "true") {
      $("form#fee_form").submit();
      $("div.loading-gif.displayInMiddle").hide();
    }
  } catch (err) {}
};

window.bidAsset = async function (
  assetAddress,
  tokenId,
  qty = 1,
  amount,
  payingTokenAddress,
  decimals = 18,
  collectionId,
  bidPayAmt
) {
  try {
    var amountInDec = roundNumber(mulBy(amount, 10 ** decimals), 0);
    var nonce_value = await getNonceValue(collectionId);
    var messageHash = ethers.utils.solidityKeccak256(
      ["address", "uint256", "address", "uint256", "uint256", "uint256"],
      [assetAddress, tokenId, payingTokenAddress, amountInDec, qty, nonce_value]
    );
    var account = await getAccounts();
    messageHash = ethers.utils.arrayify(messageHash);
    var signature = await signer.signMessage(messageHash);
    await placeBid(collectionId, signature, qty, {
      asset_address: assetAddress,
      token_id: tokenId,
      quantity: qty,
      amount: bidPayAmt,
      amount_with_fee: amount,
      payment_token_address: payingTokenAddress,
      payment_token_decimals: decimals,
    });
    await saveNonceValue(collectionId, signature, nonce_value);
    return window.bidSignSuccess(collectionId);
  } catch (err) {
    return window.bidSignFailed(err["message"]);
  }
};

window.signMessage = async function (msg) {
  try {
    var sign = signer.signMessage(msg);
    return sign;
  } catch (err) {
    return "";
  }
};

window.signSellOrder = async function (
  amount,
  decimals,
  paymentAssetAddress,
  tokenId,
  assetAddress,
  collectionId,
  sendBackTo = ""
) {
  try {
    amount = roundNumber(mulBy(amount, 10 ** decimals), 0);
    var nonce_value = await getNonceValue(collectionId);
    var messageHash = ethers.utils.solidityKeccak256(
      ["address", "uint256", "address", "uint256", "uint256"],
      [assetAddress, tokenId, paymentAssetAddress, amount, nonce_value]
    );
    var account = await getAccounts();
    messageHash = ethers.utils.arrayify(messageHash);
    var fixedPriceSignature = await signer.signMessage(messageHash, account);
    await updateSignature(collectionId, fixedPriceSignature);
    await saveNonceValue(collectionId, fixedPriceSignature, nonce_value);
    if (sendBackTo == "update") {
      return window.updateSignFixedSuccess(collectionId);
    } else {
      return window.bidSignFixedSuccess(collectionId);
    }
  } catch (err) {
    if (sendBackTo == "update") {
      return window.updateSignFixedFailed(err["message"]);
    } else {
      return window.bidSignFixedFailed(err["message"]);
    }
  }
};

// buyingAssetType = 1 # 721
// buyingAssetType = 0 # 1155
window.buyAsset = async function (
  assetOwner,
  buyingAssetType,
  buyingAssetAddress,
  tokenId,
  unitPrice,
  buyingAssetQty,
  paymentAmt,
  paymentAssetAddress,
  decimals,
  sellerSign,
  collectionId,
  asset_supply,
  nft_upload_type
) {
  try {
    paymentAmt = roundNumber(mulBy(paymentAmt, 10 ** decimals), 0);
    unitPrice = roundNumber(mulBy(unitPrice, 10 ** decimals), 0);
    const buyAsset = await fetchContract(tradeContractAddress, "trade");
    var nonce_value = await getContractSignNonce(collectionId, sellerSign);
    var account = await getAccounts();
    var orderStruct = [
      assetOwner,
      account,
      paymentAssetAddress,
      buyingAssetAddress,
      buyingAssetType,
      unitPrice,
      paymentAmt,
      tokenId,
      buyingAssetQty,
    ];
    var sellerSign = splitSign(sellerSign, nonce_value);
    var gasPrices = await gasPrice();
    var data = new ethers.utils.AbiCoder().encode(
      ["uint8", "bytes32", "bytes32", "uint256"],
      [sellerSign[0], sellerSign[1], sellerSign[2], sellerSign[3]]
    );
    var ownerSign = await sign_metadata_with_owner(data, account, collectionId);
    var receipt = await buyAsset.buyAsset(
      orderStruct,
      asset_supply,
      sellerSign,
      splitSign(ownerSign.sign, ownerSign.nonce),
      { from: account, gasLimit: 516883, gasPrice: String(gasPrices) }
    );
    receipt = await receipt.wait();
    await updateCollectionBuy(
      collectionId,
      buyingAssetQty,
      receipt.transactionHash
    );
    return window.buyPurchaseSuccess(collectionId, nft_upload_type);
  } catch (err) {
    return window.buyPurchaseFailed(err["message"]);
  }
};

window.sign_metadata_with_owner = function (data, account, collectionId) {
  var sign;
  $.ajax({
    url: `/collections/${collectionId}/sign_metadata_with_creator`,
    type: "POST",
    async: false,
    data: { data: data, address: account },
    dataType: "json",
  }).done(function (msg) {
    sign = { sign: msg["signature"], nonce: msg["nonce"] };
  });
  return sign;
};

window.executeBid = async function (
  buyer,
  buyingAssetType,
  buyingAssetAddress,
  tokenId,
  paymentAmt,
  buyingAssetQty,
  paymentAssetAddress,
  decimals,
  buyerSign,
  collectionId,
  bidId
) {
  try {
    paymentAmt = roundNumber(mulBy(paymentAmt, 10 ** decimals), 0);
    var unitPrice = 1;
    const executeBid = await fetchContract(tradeContractAddress, "trade");
    var nonce_value = await getContractSignNonce(collectionId, buyerSign);
    var account = await getAccounts();
    var orderStruct = [
      account,
      buyer,
      paymentAssetAddress,
      buyingAssetAddress,
      buyingAssetType,
      unitPrice,
      paymentAmt,
      tokenId,
      buyingAssetQty,
    ];
    var gasPrices = await gasPrice();
    var receipt = await executeBid.executeBid(
      orderStruct,
      splitSign(buyerSign, nonce_value),
      { from: account, gasLimit: 516883, gasPrice: String(gasPrices) }
    );
    receipt = await receipt.wait();
    await updateCollectionSell(
      collectionId,
      buyer,
      bidId,
      receipt.transactionHash
    );
    return window.acceptBidSuccess(collectionId);
  } catch (err) {
    return window.acceptBidFailed(err["message"]);
  }
};

window.getCurrentAccount = async function () {
  return await getAccounts();
};

window.ethBalance = async function (account = "") {
  account = account ? account : await getCurrentAccount();
  if (signer !== undefined) {
    var bal = await signer.getBalance();
    var ethBal = roundNumber(ethers.utils.formatEther(bal), 4);
    return ethBal;
  } else {
    return "0.0";
  }
};

window.updateEthBalance = async function () {
  var ethBal = await window.ethBalance(await getCurrentAccount());
  $(".curBalance").html(ethBal + "MATIC");
  $(".curEthBalance").text(ethBal);
};

window.tokenBalance = async function (contractAddress, decimals) {
  var abi = [
    {
      constant: true,
      inputs: [
        {
          name: "_owner",
          type: "address",
        },
      ],
      name: "balanceOf",
      outputs: [
        {
          name: "balance",
          type: "uint256",
        },
      ],
      payable: false,
      type: "function",
    },
  ];
  var contract = new ethers.Contract(contractAddress, abi, provider);
  var account = await getAccounts();
  var balance = await contract.balanceOf(account);
  var bal = parseInt(balance);
  balance = roundNumber(divBy(bal, 10 ** decimals), 4);
  return balance;
};

window.getNetworkType = async function () {
  var type = await provider.getNetwork();
  return type;
};

async function showTermsCondition(account, ethBal, networkType) {
  var account = account || (await getCurrentAccount());
  $.magnificPopup.open({
    closeOnBgClick: false,
    enableEscapeKey: false,
    items: {
      src: "#terms-and-condition",
    },
    type: "inline",
  });
  $("#account").val(account);
  $("#eth_balance_tc").val(ethBal);
  $("#network_type").val(networkType);
}

async function load(shouldDestroySession = false) {
  const account = await loadWeb3();
  const ethBal = await ethBalance(account);
  return await createDeleteSession(account, ethBal, shouldDestroySession);
}

async function createDeleteSession(account, balance, shouldDestroySession) {
  const networkType = await getNetworkType();
  const isValidUserResp = await isValidUser(account, selectedWallet);
  if (isValidUserResp.user_exists) {
    await createUserSession(account, balance, shouldDestroySession);
    if (shouldDestroySession) {
      window.location.href = "/";
    } else {
      return true;
    }
  } else {
    if (gon.session) {
      if (account) {
        await destroySession();
      }
      window.location.href = "/";
    } else {
      showTermsCondition(account, balance, networkType);
      return false;
    }
  }
}

window.disconnect = async function () {
  await destroySession();
  window.location.href = "/";
};

async function destroySession() {
  if (gon.session) {
    try {
      account = await getCurrentAccount();
      await destroyUserSession(account);
      await web3AuthInstance.logout();
    } catch {
      window.location.reload();
    }
  }
}

async function connect(wallet) {
  if (wallet == "metamask") {
    if (!window.ethereum) {
      toastr.error("Please install Metamask Extension to your browser.");
      return;
    }
  }
  selectedWallet = wallet;
  const status = await load(false);
  if (status) {
    window.location.href = "/";
  }
}
window.connect = connect;

$(document).on("click", ".connect-wallet", async function (e) {
  e.preventDefault();
  if (!gon.wallet) {
    window.show_modal("#connect_to_wallet");
    return;
  }
});

window.proceedWithLoad = async function () {
  var account = $("#account").val();
  const ethBal = $("#eth_balance").text();
  const networkType = $("#network_type").val();
  if ($("#condition1").is(":checked") && $("#condition2").is(":checked")) {
    await createUserSession(account, ethBal, networkType);
    window.location.href = "/";
  } else {
    toastr.error("Please accept the conditions to proceed");
  }
};

window.loadUser = async function () {
  if (gon.session) {
    if (
      web3AuthInstance.status == "ready" &&
      !web3AuthInstance.connectedAdapterName
    ) {
      return disconnect();
    }
    await load();
  }
};

window.gasPrice = function () {
  var init_gasPrice = "400000000000";
  try {
    var request = $.ajax({
      url: `/gas_price`,
      async: false,
      type: "GET",
    });
    request.done(function (msg) {
      if (msg["gas_price"] != "") {
        init_gasPrice = parseInt(msg["gas_price"]["fastest"]) * 10 ** 9;
      }
    });
  } catch (err) {}
  return init_gasPrice;
};

function providerEvents() {
  if (!provider?.provider) return;

  const eventProvider = provider.provider?.overrideIsMetaMask
    ? provider.provider.selectedProvider
    : provider.provider;
  eventProvider.on("accountsChanged", function () {
    if (gon.session) {
      load(true);
    }
  });
  eventProvider.on("chainChanged", function () {
    if (gon.session) {
      load(true);
    }
  });
}

window.mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
